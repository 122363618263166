<template>
  <div class="indexnav">
    <div class="indextop">
        <div class="topitme">
            <span class="lintbom">卓翔14年品牌历程，专注工商财税一站式服务平台</span><span>服务热线:&nbsp;&nbsp;<b style="color: red;">0755-21006061</b></span>
        </div>
    </div>
    <div class="indexnav_img">
      <img src="../../assets/homepage/logo.png" />
    </div>
    <div class="indexnav_ul">
      <ul>
        <li><router-link to="/" class="indexa"> 首页</router-link></li>
        <li><router-link to="/gongsizhuce" class="indexa">公司注册</router-link></li>
        <li class="ddd">
            <router-link to="/dianshang" class="indexa">代理记账</router-link>
        </li>
<!--        <li><router-link to="/shuiwuzouhua" class="indexa">税务筹办</router-link></li>
        <li><router-link to="/zizhibanli" class="indexa">办理资质</router-link></li>
        <li><router-link to="/zuceshangbiao" class="indexa">注册商标</router-link></li> -->
        <li><router-link to="/about" class="indexa">关于我们</router-link></li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
.indextop{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f8f8f8;
    width: 100%;
    height: 40px;
    font-size: 14px;
}
.indextop .topitme{
    margin: 0 auto;
    /* border: 1px red solid; */
    height: 40px;
    width: 1200px;
    line-height: 40px;
    text-align: left;
}
.indextop .topitme .lintbom::after{
    content: ' ';
    display: inline-block;
    background-color: #000;
    width: 1px;
    /* height: 20px; */
    margin: 0px 15px 0px;
    line-height: 60px;
    align-items: center;
    padding-top: 15px;
}
.ddd:hover .dropdown_content {
  display: block;
  z-index: 999;
}
.divsss p {
  text-align: center;
  line-height: 40px;
  color: #000;
}
/* .divsss {
  width: 140px;
  height: 48px;
  background: #ffffff;
 

} */
.dropdown_content {
  display: none;
  position: absolute;
  width: 140px;
  height: 306px;
  background: #ffffff;
  top: 71px;
  left: 875px;
  /* left: 192px; */
}
.indexnav {
  width: 1200px;
  height: 81px;
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}

.indexnav img {
  width: 181px;
  height: 54x;
}

.indexnav_ul {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: black;
  line-height: 22px;
}
.indexnav_ul ul{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.indexnav_ul li {
  list-style-type: none;
  color: black;
  display: inline-block;
  padding: 18px;
  text-decoration: none;
  font-weight: 500px;
  /* text-decoration: underline; */
}
.indexnav1 ul li a {
  margin: 2px;
  display: inline-block;
  color: black;
  font-family: "Microsoft Yahei";
  font-size: 16px;
  text-decoration: none;
  list-style: none;
  text-decoration: underline;
}
.indexnav_ul li a:hover {
  color: red;
}
.indexnav_ul li a::selection{
    color: red;
}

.indexnav_ul li {
  list-style-type: none;
}
.indexa{
    color: #000;
}
.indexa:hover{
    cursor: pointer;
    color: red;
}
</style>
<script>
export default {
  name: "Header",
};
</script>


